import logo from "../../logo_n2.png";
import { Link } from "react-router-dom";
import { DarkModeToggle } from "../DarkModeToggle";

function NavBar() {
  return (
    <div className='fixed w-screen z-30 top-0 bg-white dark:bg-dark-900 border-b border-gray-100 dark:border-dark-800 shadow-sm transition-colors duration-200' id='nav'>
      <div className='py-2 text-sm font-medium text-gray-600 dark:text-gray-300 bg-sky-50/50 dark:bg-dark-800/50 backdrop-blur-sm' id='quote'>
        <div className='container mx-auto px-4 text-center font-roboto'>
          We create wonders with workforce transformation! A women-owned
          minority business enterprise based in Texas.
        </div>
      </div>
      <div className='container mx-auto px-4'>
        <div className='flex items-center justify-between h-16'>
          <Link
            to='/'
            className='flex items-center'
            onClick={scrollToTpp}
          >
            <img
              src={logo}
              className='h-12 lg:h-14 p-2 transition-all duration-300 ease-linear rounded-2xl hover:scale-105'
              alt={"qm_logo"}
              id='icon'
            />
          </Link>

          <div className='flex-1 flex justify-end items-center'>
            <span className='hidden lg:block'>
              <DesktopMenu />
            </span>
            <span className='block lg:hidden'>
              <MobileMenu />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const MobileMenu = () => {
  const toggleMenu = () => {
    const menu = document.getElementById("m-menu");
    if (menu) {
      if (menu.classList.contains("pointer-events-none")) {
        // Opening
        menu.classList.remove("pointer-events-none");
        menu.classList.remove("opacity-0");
        setTimeout(() => {
          menu.classList.remove("translate-x-full");
        }, 10);
      } else {
        // Closing
        menu.classList.add("translate-x-full");
        setTimeout(() => {
          menu.classList.add("opacity-0");
          menu.classList.add("pointer-events-none");
        }, 300);
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-end gap-2 pr-4">
        <DarkModeToggle />
        <div
          tabIndex={0}
          role='button'
          className='btn btn-ghost lg:hidden'
          onClick={toggleMenu}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h8m-8 6h16'
            />
          </svg>
        </div>
      </div>
      <ul
        className='fixed bg-white dark:bg-dark-900 w-screen h-screen left-0 transform transition-transform duration-300 ease-in-out translate-x-full text-base font-medium text-gray-600 dark:text-white opacity-0 pointer-events-none'
        id='m-menu'
      >
        <div className="grid grid-cols-1 gap-6 p-8 text-left">
          <MenuLink link='/' text='Home' />
          <MenuLink link='/about' text='About us' />
          <MenuLink
            link={[
              { text: "Professional Staffing", link: "/staffing" },
              { text: "QA Services", link: "/consulting" },
              { text: "Software Development", link: "/solutions" },
              { text: "Digital Transformation", link: "/training" },
            ]}
            text='Services'
          />
          <MenuLink link='/jobs' text='Jobs' />
          <div className="flex justify-start">
            <Apppointment />
          </div>
        </div>
      </ul>
    </>
  );
};

const DesktopMenu = () => {
  return (
    <div className="flex items-center gap-6">
      <nav className="flex items-center space-x-1">
        <MenuLink link='/' text='Home' />
        <MenuLink
          link={[
            { text: "Professional Staffing", link: "/staffing" },
            { text: "QA Services", link: "/consulting" },
            { text: "Software Development", link: "/solutions" },
            { text: "Digital Transformation", link: "/training" },
          ]}
          text='Services'
        />
        <MenuLink link='/about' text='About us' />
        <MenuLink link='/jobs' text='Jobs' />
        <Apppointment />
      </nav>
      <DarkModeToggle />
    </div>
  );
};

const closeMenu = () => {
  const menu = document.getElementById("m-menu");
  if (menu) {
    menu.classList.add("translate-x-full");
    setTimeout(() => {
      menu.classList.add("opacity-0");
      menu.classList.add("pointer-events-none");
    }, 300);
  }
};

const MenuLink = ({ link, text }) => {
  if (Array.isArray(link)) {
    return (
      <div className='dropdown'>
        <div
          tabIndex={0}
          role='button'
          className='px-4 py-3 font-roboto font-medium text-base no-underline text-gray-600 dark:text-white transition-all duration-300 hover:text-sky-700 dark:hover:text-sky-300 text-left'
        >
          {text}
        </div>
        <ul
          tabIndex={0}
          className='menu dropdown-content z-[10] shadow-lg bg-white dark:bg-dark-900 text-gray-600 dark:text-white rounded-lg w-auto mt-2 font-medium text-sm'
        >
          {link.map((item) => (
            <li
              key={item.link}
              className='hover:bg-sky-50 dark:hover:bg-dark-800'
              onClick={() => {
                closeMenu();
                scrollToTpp();
              }}
            >
              <Link 
                to={item.link} 
                onClick={scrollToTpp} 
                className="px-4 py-3 text-gray-600 dark:text-white hover:text-sky-700 dark:hover:text-sky-300 font-roboto whitespace-nowrap text-left"
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return (
      <Link
        to={link}
        className='px-4 py-3 font-roboto font-medium text-base no-underline text-gray-600 dark:text-white transition-all duration-300 hover:text-sky-700 dark:hover:text-sky-300 text-left'
        key={text}
        onClick={() => {
          closeMenu();
          scrollToTpp();
        }}
      >
        {text}
      </Link>
    );
  }
};

const Apppointment = () => {
  return (
    <div className='ml-2'>
      <a
        href='#contact'
        className='inline-flex items-center px-5 py-2.5 font-roboto font-medium text-white dark:text-white bg-sky-600 hover:bg-[#15407A] dark:hover:bg-[#15407A] rounded-lg shadow-md hover:shadow-lg transition-all duration-200'
        onClick={() => {
          document.getElementById("m-menu")?.classList?.add("hidden");
        }}
      >
        Let's Connect
      </a>
    </div>
  );
};

export const scrollToTpp = () => {
  document.getElementById("loading")?.classList?.remove("hidden");
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export default NavBar;
