import React from 'react';
import { useDarkMode } from './DarkModeProvider';
import { FaSun, FaMoon } from 'react-icons/fa';

export function DarkModeToggle() {
  const { isDarkMode, toggleDarkMode, isLoading } = useDarkMode();

  return (
    <button
      onClick={toggleDarkMode}
      className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-dark-700 transition-colors duration-150 relative"
      aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
      disabled={isLoading}
    >
      {isDarkMode ? (
        <FaSun className="w-5 h-5 text-yellow-400 animate-fadeIn" />
      ) : (
        <FaMoon className="w-5 h-5 text-gray-600 animate-fadeIn" />
      )}
    </button>
  );
}
