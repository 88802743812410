import { Link } from 'react-router-dom';
import { scrollToTpp } from '../navbar';
import {
  FaTwitter,
  FaLinkedin,
  FaFacebook,
  FaInstagram,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className='relative w-full bg-white dark:bg-dark-900 text-gray-800 dark:text-gray-200 transition-colors duration-200 z-40'>
      <div className='max-w-7xl mx-auto px-4 py-10'>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-8'>
          {/* Company Info */}
          <div className='space-y-4'>
            <h3 className='text-lg font-roboto font-bold text-sky-900 dark:text-sky-400'>Qualmission</h3>
            <p className='text-sm text-gray-600 dark:text-gray-400 leading-relaxed'>
              Empowering businesses with innovative IT solutions and exceptional talent.
            </p>
            <div className='flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400'>
              <FaEnvelope className='w-4 h-4' />
              <a 
                href='mailto:hr@qualmission.com'
                className='hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
              >
                hr@qualmission.com
              </a>
            </div>
            <div className='flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400'>
              <FaPhone className='w-4 h-4' />
              <span>+1 (123) 456-7890</span>
            </div>
          </div>

          {/* Quick Links */}
          <div className='space-y-4'>
            <h3 className='text-lg font-roboto font-bold text-sky-900 dark:text-sky-400'>Quick Links</h3>
            <ul className='space-y-2 text-sm'>
              <li>
                <Link 
                  to='/' 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link 
                  to='/about' 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link 
                  to='/services' 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link 
                  to='/contact' 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div className='space-y-4'>
            <h3 className='text-lg font-roboto font-bold text-sky-900 dark:text-sky-400'>Services</h3>
            <ul className='space-y-2 text-sm'>
              <li className="mb-4">
                <Link 
                  to="/staffing" 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  Professional Staffing
                </Link>
              </li>
              <li className="mb-4">
                <Link 
                  to="/consulting" 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  QA Services
                </Link>
              </li>
              <li className="mb-4">
                <Link 
                  to="/solutions" 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  Software Development
                </Link>
              </li>
              <li className="mb-4">
                <Link 
                  to="/training" 
                  className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                  onClick={scrollToTpp}
                >
                  Digital Transformation
                </Link>
              </li>
            </ul>
          </div>

          {/* Connect */}
          <div className='space-y-4'>
            <h3 className='text-lg font-roboto font-bold text-sky-900 dark:text-sky-400'>Connect With Us</h3>
            <nav className='flex gap-4'>
              <Link
                to='https://www.linkedin.com/company/qualmission'
                target='_blank'
                rel='noopener noreferrer'
                className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                aria-label='LinkedIn'
              >
                <FaLinkedin className='w-6 h-6' />
              </Link>
              <Link 
                to='https://twitter.com/qualmission' 
                target='_blank'
                rel='noopener noreferrer'
                className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                aria-label='Twitter'
              >
                <FaTwitter className='w-6 h-6' />
              </Link>
              <Link
                to='https://www.facebook.com/profile.php?id=100091598538011'
                target='_blank'
                rel='noopener noreferrer'
                className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                aria-label='Facebook'
              >
                <FaFacebook className='w-6 h-6' />
              </Link>
              <Link 
                to='https://www.instagram.com/qualmission/' 
                target='_blank'
                rel='noopener noreferrer'
                className='text-gray-600 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
                aria-label='Instagram'
              >
                <FaInstagram className='w-6 h-6' />
              </Link>
            </nav>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className='mt-10 pt-8 border-t border-gray-200 dark:border-gray-700'>
          <div className='flex flex-col md:flex-row justify-between items-center gap-4 text-sm text-gray-600 dark:text-gray-400'>
            <div className='flex items-center gap-2'>
              <span> 2024 Qualmission. All rights reserved.</span>
            </div>
            <div className='flex items-center gap-6'>
              <Link 
                to='/privacy-policy' 
                onClick={scrollToTpp}
                className='hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
              >
                Privacy Policy
              </Link>
              <Link 
                to='/terms' 
                onClick={scrollToTpp}
                className='hover:text-sky-600 dark:hover:text-sky-400 transition-colors duration-200'
              >
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
