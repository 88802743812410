import React, { createContext, useContext, useEffect, useState } from 'react';
import LoadingQM from './loading';

const DarkModeContext = createContext();

export function useDarkMode() {
  return useContext(DarkModeContext);
}

export function DarkModeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check local storage first
    const saved = localStorage.getItem('darkMode');
    if (saved !== null) {
      return JSON.parse(saved);
    }
    // If not in local storage, check system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const root = window.document.documentElement;
    if (isDarkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setIsDarkMode(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleDarkMode = () => {
    setIsLoading(true);
    // Delay the theme change for smooth animation
    setTimeout(() => {
      setIsDarkMode(prev => !prev);
      // Keep loading screen a bit longer for animation to complete
      setTimeout(() => {
        setIsLoading(false);
      }, 800); // Increased duration to show more of the animation
    }, 300);
  };

  const value = {
    isDarkMode,
    toggleDarkMode,
    isLoading
  };

  return (
    <DarkModeContext.Provider value={value}>
      {children}
      {isLoading && <LoadingQM />}
    </DarkModeContext.Provider>
  );
}
