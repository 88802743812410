import { useState } from "react";
import { BsClipboardCheck } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div
      className='bg-white dark:bg-dark-900 w-full min-h-screen flex items-center py-16 md:py-24 transition-colors duration-200'
      id='contact'
    >
      <div className="w-full max-w-8xl mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 xl:grid-cols-2 overflow-hidden rounded-2xl shadow-xl">
          <FeedBackForm />
          <ContactInfo />
        </div>
      </div>
    </div>
  );
};

const ContactInfo = () => {
  const [emailCopied, setEmailCopied] = useState(false);
  const email = "hr@qualmission.com";
  const linkedinUrl = "https://www.linkedin.com/company/qualmission-llc";

  const handleCopyEmail = async () => {
    try {
      await navigator.clipboard.writeText(email);
      setEmailCopied(true);
      setTimeout(() => setEmailCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy email:', err);
    }
  };

  return (
    <div
      className='h-full p-8 md:p-12 bg-sky-900 dark:bg-sky-950 text-white transition-colors duration-200'
      data-aos='flip-down'
      data-aos-delay='500'
    >
      <div className='h-full flex flex-col justify-between max-w-3xl mx-auto space-y-12'>
        <div className='space-y-10'>
          <div className='text-lg md:text-xl text-gray-100 dark:text-gray-200 leading-8 md:leading-9 font-roboto font-light tracking-wide'>
            We are firm believers in performing ethical business operations and
            cultivating values-based relationships with clients, workers, and anyone
            else that joins us in the organization's growth.
          </div>

          <div className='text-center'>
            <h2 className='text-2xl md:text-3xl font-bold tracking-wider text-white dark:text-gray-100 font-roboto'>
              Get in touch
            </h2>
          </div>

          <div className='space-y-8 text-white'>
            <p className='text-lg md:text-xl leading-8 md:leading-9 text-gray-100 dark:text-gray-200 font-roboto font-light tracking-wide'>
              We will help you to find the right best Fit. We have new openings
              everyday! E-mail your resume now.
            </p>

            <div className='flex items-center gap-4'>
              <div className="flex items-center gap-3">
              </div>
            </div>
          </div>
        </div>

        <div className='space-y-8'>
          <div className='space-y-4'>
            <div className='space-y-3'>
              <Link 
                className='block text-lg md:text-xl text-sky-200 hover:text-white underline decoration-sky-400/50 hover:decoration-white transition-colors duration-200 font-roboto font-light' 
                to={`mailto:${email}`}
              >
                {email}
              </Link>
              <button
                className={`flex items-center gap-2.5 text-sm md:text-base cursor-pointer font-roboto font-light ${
                  emailCopied ? "text-lime-400 dark:text-lime-300" : "text-white/80 hover:text-white"
                } transition-colors duration-200`}
                onClick={handleCopyEmail}
                type="button"
                aria-label={emailCopied ? "Email copied!" : "Copy email address"}
              >
                <BsClipboardCheck className='text-lg md:text-xl' />
                <span>{emailCopied ? "Copied!" : "Copy email"}</span>
              </button>
            </div>
          </div>

          <div className='space-y-4'>
            <div className='text-base md:text-lg text-white font-roboto font-light tracking-wide'>
              Follow us on LinkedIn to see latest career updates
            </div>
            <div className='space-y-3'>
              <Link
                to="https://www.linkedin.com/company/qualmission-llc"
                target="_blank"
                rel="noopener noreferrer"
                className='inline-flex items-center gap-2.5 text-sky-200 hover:text-white underline decoration-sky-400/50 hover:decoration-white transition-colors duration-200 font-roboto font-light'
              >
                <FaLinkedin className='w-6 h-6 md:w-7 md:h-7' />
                <span className='text-base md:text-lg tracking-wide'>Qualmission</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedBackForm = () => {
  const [inputs, setInputs] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const body = `Name: ${inputs.name}\nEmail: ${inputs.email}\nPhone: ${inputs.phone}\nSubject: ${inputs.subject}\nMessage: ${inputs.message}\n`;

    window.Email.send({
      SecureToken: "fd8d54c2-9fe7-4bdb-a50b-6d84f0bee0d2",
      To: "hr@qualmission.com",
      From: "admin@qualmission.in",
      Subject: "Website Query",
      Body: body,
    }).then((message) => {
      if (message === "OK") {
        setSubmitted(true);
        setError(false);
        setInputs({});
      } else {
        setSubmitted(false);
        setError(true);
      }
    });
  };

  return (
    <div
      className='h-full p-8 md:p-12 bg-white dark:bg-dark-800 transition-colors duration-200'
      data-aos='flip-down'
      data-aos-delay='500'
    >
      <div className='h-full flex flex-col max-w-2xl mx-auto space-y-10'>
        <h2 className='text-3xl md:text-4xl font-bold text-sky-950 dark:text-sky-400 tracking-wide font-roboto'>
          Get in Touch
        </h2>
        <p className='text-lg text-sky-950 dark:text-gray-300 font-roboto font-light'>
          We'd love to hear from you. Please fill out this form.
        </p>
        <form
          className='flex-grow flex flex-col space-y-8'
          onSubmit={handleSubmit}
        >
          {!submitted && (
            <>
              <div className='space-y-6'>
                <input
                  type='text'
                  name='name'
                  placeholder='Your Name'
                  className='w-full px-4 py-3 bg-gray-50 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 rounded-lg focus:outline-none focus:border-sky-500 dark:focus:border-sky-400 text-sky-950 dark:text-gray-200 placeholder:text-gray-500 transition-colors duration-200'
                  required
                  value={inputs.name || ""}
                  onChange={handleChange}
                />
                <input
                  type='email'
                  name='email'
                  placeholder='Email Address'
                  className='w-full px-4 py-3 bg-gray-50 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 rounded-lg focus:outline-none focus:border-sky-500 dark:focus:border-sky-400 text-sky-950 dark:text-gray-200 placeholder:text-gray-500 transition-colors duration-200'
                  required
                  value={inputs.email || ""}
                  onChange={handleChange}
                />
                <input
                  type='tel'
                  name='phone'
                  placeholder='Phone Number'
                  className='w-full px-4 py-3 bg-gray-50 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 rounded-lg focus:outline-none focus:border-sky-500 dark:focus:border-sky-400 text-sky-950 dark:text-gray-200 placeholder:text-gray-500 transition-colors duration-200'
                  required
                  value={inputs.phone || ""}
                  onChange={handleChange}
                />
                <input
                  type='text'
                  name='subject'
                  placeholder='Subject'
                  className='w-full px-4 py-3 bg-gray-50 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 rounded-lg focus:outline-none focus:border-sky-500 dark:focus:border-sky-400 text-sky-950 dark:text-gray-200 placeholder:text-gray-500 transition-colors duration-200'
                  required
                  value={inputs.subject || ""}
                  onChange={handleChange}
                />
                <textarea
                  name='message'
                  rows='4'
                  placeholder='Your Message'
                  className='w-full px-4 py-3 bg-gray-50 dark:bg-dark-700 border border-gray-300 dark:border-dark-600 rounded-lg focus:outline-none focus:border-sky-500 dark:focus:border-sky-400 text-sky-950 dark:text-gray-200 placeholder:text-gray-500 transition-colors duration-200 resize-none'
                  required
                  value={inputs.message || ""}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button className='w-full py-3 text-lg text-white bg-sky-700 hover:bg-sky-800 rounded-lg transition-all duration-200 ease-in-out hover:scale-[1.02] font-roboto font-light'>
                Submit
              </button>
            </>
          )}
          {submitted && (
            <div className='text-center space-y-4'>
              <div className='text-2xl text-sky-950 dark:text-sky-400 font-roboto font-light'>
                Thank you for your message!
              </div>
              <div className='text-lg text-sky-950 dark:text-gray-300 font-roboto font-light'>
                We'll get back to you soon.
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
