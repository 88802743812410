import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AOS from "aos";
import { DarkModeProvider } from "./component/DarkModeProvider";
import logo from "./logo_n3.png";

import Layout from "./component/Layout";
const Home = React.lazy(() => import("./component/home"));
const Privacy = React.lazy(() => import("./component/privacy"));
const PageNotFound = React.lazy(() => import("./component/pagenotfound"));
const AboutUs = React.lazy(() => import("./component/aboutus"));
const Staffing = React.lazy(() => import("./component/services/Staffing"));
const QAService = React.lazy(() => import("./component/services/QAService"));
const SoftwareDevelopment = React.lazy(() => import("./component/services/SoftwareDevelopment.js"));
const DigitalTransform = React.lazy(() => import("./component/services/DigitalTransform"));
const JobOpenings = React.lazy(() => import("./component/jobs/index.js"));

function LoadingSpinner() {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white dark:bg-dark-900 transition-colors duration-300">
      <div className="relative">
        <img 
          src={logo} 
          className="w-48 md:w-64 object-contain transition-opacity duration-300" 
          alt="Qualmission Logo" 
        />
        <div className="absolute bottom-0 left-0 right-0 mt-4 flex justify-center">
          <div className="flex space-x-2">
            <div className="w-2 h-2 bg-sky-400 dark:bg-sky-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
            <div className="w-2 h-2 bg-sky-400 dark:bg-sky-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div className="w-2 h-2 bg-sky-400 dark:bg-sky-500 rounded-full animate-bounce"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      easing: 'ease-out-cubic',
    });
  }, []);

  return (
    <DarkModeProvider>
      <div className='App min-h-screen bg-white dark:bg-dark-900 text-gray-900 dark:text-gray-100 transition-colors duration-200'>
        <BrowserRouter>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path='/' element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='/privacy-policy' element={<Privacy />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/staffing' element={<Staffing />} />
                <Route path='/consulting' element={<QAService />} />
                <Route path='/solutions' element={<SoftwareDevelopment />} />
                <Route path='/training' element={<DigitalTransform />} />
                <Route path='/jobs' element={<JobOpenings />} />
                <Route path='*' element={<PageNotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </DarkModeProvider>
  );
}

export default App;
