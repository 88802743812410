import React, { useEffect, useState } from "react";
import logo from "../../logo_n3.png";

function LoadingQM() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Slight delay before showing content for smoother animation
    const timer = setTimeout(() => setShowContent(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className='fixed inset-0 z-50 flex items-center justify-center bg-white dark:bg-dark-900 transition-colors duration-150 animate-fadeIn'
      id='loading'
    >
      <div className={`relative transform transition-all duration-500 ${showContent ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
        <div className="relative">
          <img 
            src={logo} 
            className='w-48 md:w-64 object-contain transition-all duration-300 animate-float' 
            alt="Qualmission Logo" 
          />
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 dark:via-black/20 to-transparent animate-pulse" />
        </div>
        
        {/* Loading text */}
        <div className="text-center mt-4 space-y-2">
          <div className="flex justify-center space-x-2">
            <div className="w-2 h-2 bg-sky-400 dark:bg-sky-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
            <div className="w-2 h-2 bg-sky-400 dark:bg-sky-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div className="w-2 h-2 bg-sky-400 dark:bg-sky-500 rounded-full animate-bounce"></div>
          </div>
        </div>

        {/* Decorative elements */}
        <div className="absolute -inset-4 md:-inset-6">
          <div className="w-full h-full rotate-45 bg-gradient-to-r from-transparent via-sky-200/10 dark:via-sky-400/10 to-transparent animate-pulse" />
        </div>
      </div>
    </div>
  );
}

export default LoadingQM;
